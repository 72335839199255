import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { PullRefresh } from "vant";
import { Empty } from "vant";
import "@/themes/font.scss";
import "@mdi/font/css/materialdesignicons.css";
import VueParallaxJs from "vue-parallax-js";
import VueGtag from "vue-gtag";
import NoSleep from "nosleep.js/dist/NoSleep.min.js";

console.log("@@@当前环境", process.env["VUE_APP_MODE"]);

if (
  process.env["VUE_APP_MODE"] === "DEV" ||
  process.env["VUE_APP_MODE"] === "LOCAL"
) {
  if (localStorage.getItem("debug")) {
    // 使用vconsole
    const VConsole = require("vconsole");
    Vue.prototype.$VConsole = new VConsole();
  }
}

const options = {
  // minWidth: 50,   /* minumum window width for parallax to take effect */
  // className: String,  /* this class gets added to all elements
  //                     that are being animated, by default none */
  container:
    "#main" /* element that actually scrolls, by default it's window */,
};

Vue.config.productionTip = false;
// add vue-parallax-js to vue
Vue.use(VueParallaxJs, options);
Vue.use(PullRefresh);
Vue.use(Empty);
Vue.use(
  VueGtag,
  {
    config: {
      id: "G-MZM0FCTDE8",
      params: {
        send_page_view: true,
      },
      pageTrackerScreenviewEnabled: true,
    },
  },
  router
);

Vue.prototype.$NoSleep = NoSleep;

const onPlusReady = function (callback, context = this) {
  if (window.plus) {
    callback.call(context);
  } else {
    document.addEventListener("plusready", callback.bind(context));
  }
};

Vue.mixin({
  beforeCreate() {
    onPlusReady(() => {
      this.plusReady = true;
    }, this);
  },
  methods: {
    onPlusReady: onPlusReady,
  },
});

const vue = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

export default vue;
