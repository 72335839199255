import { get, post } from "./request";
// USERINFO 接口
// export const api_userinfo = data => get("admin/userinfo", data);

export default {
  // 获取文章内容
  getPassageContent(data) {
    return get("lclass/getPassageContent", data);
  },
  // 获取文章列表
  getPassageList(data) {
    return get("lclass/getPassageList", data);
  },

  // 获取课程详情
  getClassDetail(data) {
    return get("lclass/getClassDetail", data);
  },

  // 获取课程列表
  getClassList(data) {
    return get("lclass/getClassList", data);
  },

  // 获取课程列表
  getClassPptList(data) {
    return get("lclass/getPptClass", data);
  },

  // 同步学习时长
  analyseFocus(data) {
    return post("analyse/analyseFocus", data);
  },

  // 同步学习时长
  analyseTime(data) {
    return post("analyse/analyseTime", data);
  },

  // 同步单词闪过
  binGo(data) {
    return post("analyse/binGo", data);
  },

  // 标记但此数值
  setKnown(data) {
    return post("lword/setKnown", data);
  },

  // 同步单词闪过
  syncWordProgress(data) {
    return post("lword/syncWordProgress", data);
  },

  // 获取今日单词
  syncSingleWord(data) {
    return post("lword/syncSingleWord", data);
  },

  // 获取今日单词
  getTodayWords(data) {
    return get("lword/getTodayWords", data);
  },

  // 获取个人单词本
  getMyWords(data) {
    return get("lword/getMyWords", data);
  },

  // 从单词表删除
  removeToUserWordTable(data) {
    return post("lword/removeWord", data);
  },

  // 添加到单词表
  addToUserWordTable(data) {
    return post("lword/addWord", data);
  },

  getHomeData(data) {
    return get("home/getHomeData", data);
  },

  setUserGoleConfig(data) {
    return post("setting/setUserGoleConfig", data);
  },

  setUserCardConfig(data) {
    return post("setting/setUserCardConfig", data);
  },

  setUserMileStone(data) {
    return post("setting/setUserMileStone", data);
  },

  getUserMileStone(data) {
    return get("home/getUserMileStone", data);
  },

  getMotto(data) {
    return get("home/getMotto", data);
  },

  sendSingleVerifyCode(data) {
    return post("auth/sendSingleVerifyCode", data);
  },

  doRegister(data) {
    return post("auth/register", data);
  },

  doLogin(data) {
    return post("auth/login", data);
  },

  renewAuth(data) {
    return post("auth/renew", data);
  },

  getRecommandWordList(data) {
    return get("dict/associate", data);
  },

  getWordDetail(data) {
    return get("dict/search", data);
  },

  quickSearchWord(data) {
    return get("dict/quickSearchWord", data);
  },

  getWordPronounce(data) {
    return get("dict/pronounce", data);
  },

  remain(data) {
    return post("dict/search", data);
  },
};
