<template>
  <div class="chart">
    <div id="pieChart"></div>

    <div class="tool-tip">
      <div
        class="group"
        v-show="grapheDetails.a.value !== 100 && grapheDetails.b.value !== 100"
      >
        <transition-group name="tooltip">
          <div
            class="group"
            v-for="(items, index) in grapheDetails"
            :key="items.id"
            v-show="showIndex === index"
          >
            <span
              class="target"
              v-show="showIndex !== 'status' && showIndex === index"
              >{{ chartOptions.names[index] }}</span
            >
            <span
              class="value"
              :style="`color:${chartOptions.colors[index]}`"
              v-show="showIndex !== 'status' && showIndex === index"
              >{{ items.value }}%</span
            >
          </div></transition-group
        >
      </div>
      <transition name="tooltip">
        <div
          class="group-success"
          v-show="
            grapheDetails.a.value === 100 && grapheDetails.b.value === 100
          "
          @click="showFinishAnimation"
        >
          <v-img
            :class="inAnimation ? 'finish-img' : ''"
            @animationend="inAnimation = false"
            ref="finishImg"
            width="4rem"
            height="3.5rem"
            :src="require('@/assets/uiimg/good.png')"
          >
          </v-img>

          <span class="word">目标完成</span>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Highcharts from "highcharts/highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsSolidGauge from "highcharts-solid-gauge";
import { buttonVibrate } from "@/mixin/mixin";
HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);
let chartOptions = {
  chart: {
    backgroundColor: null,
  },
  colors: {
    a: "#f1915b",
    b: "#505050",
  },
  names: {
    a: "任务目标",
    b: "时长目标",
  },
  // colors: ["#f1915b", "#505050", "#7b58a1"],
  title: {
    style: {
      color: "silver",
    },
  },
  tooltip: {
    style: {
      color: "silver",
    },
  },
};
Highcharts.setOptions(chartOptions);
export default {
  name: "pieChart",
  props: ["grapheDetails"],
  mixins: [buttonVibrate],
  watch: {
    grapheDetails: {
      handler(newValue) {
        if (this.chart) {
          console.log(newValue);
          this.chart.series[0].setData([
            {
              y: newValue.a.value,
            },
          ]);
          this.chart.series[1].setData([
            {
              y: newValue.b.value,
            },
          ]);
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      chartOptions,
      chart: null,
      showIndex: "a",
      showInterVal: null,
      inAnimation: true,
      options: {
        chart: {
          type: "solidgauge",
          marginTop: 5,
        },
        credits: { enabled: false }, //不显示highCharts版权信息
        title: {
          text: "",
          style: {
            fontSize: "0px",
          },
        },
        tooltip: {
          borderWidth: 0,
          backgroundColor: "none",
          shadow: false,
          style: {
            display: "none",
          },
          pointFormat: "",
        },
        pane: {
          startAngle: 0,
          endAngle: 360,
          background: [
            {
              // Track for Move
              outerRadius: "107%",
              innerRadius: "94%",
              backgroundColor: Highcharts.Color(
                Highcharts.getOptions().colors.a
              )
                .setOpacity(0.1)
                .get(),
              borderWidth: 0,
            },
            {
              // Track for Exercise
              outerRadius: "87%",
              innerRadius: "74%",
              backgroundColor: Highcharts.Color(
                Highcharts.getOptions().colors.b
              )
                .setOpacity(0.1)
                .get(),
              borderWidth: 0,
            },
            // {
            //   // Track for Stand
            //   outerRadius: "73%",
            //   innerRadius: "60%",
            //   backgroundColor: Highcharts.Color(
            //     Highcharts.getOptions().colors[2]
            //   )
            //     .setOpacity(0.1)
            //     .get(),
            //   borderWidth: 0,
            // },
          ],
        },
        yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          tickPositions: [],
        },
        plotOptions: {
          solidgauge: {
            borderWidth: "0.7rem",
            dataLabels: {
              enabled: false,
            },
            linecap: "round",
            stickyTracking: false,
          },
        },
        series: [
          {
            id: "a",
            name: "时长目标",
            borderColor: Highcharts.Color(Highcharts.getOptions().colors.a)
              .setOpacity(0.8)
              .get(),
            data: [
              {
                color: Highcharts.Color(Highcharts.getOptions().colors.a)
                  .setOpacity(0.8)
                  .get(),
                // color:'yellow',
                radius: "100%",
                innerRadius: "100%",
                y: this.grapheDetails.a.value,
              },
            ],
            events: {
              click: this.chartclickA,
            },
          },
          {
            id: "b",
            name: "任务目标",
            borderColor: Highcharts.Color(Highcharts.getOptions().colors.b)
              .setOpacity(0.8)
              .get(),
            data: [
              {
                color: Highcharts.Color(Highcharts.getOptions().colors.b)
                  .setOpacity(0.8)
                  .get(),
                radius: "80%",
                innerRadius: "80%",
                y: this.grapheDetails.b.value,
              },
            ],
            events: {
              click: this.chartclickB,
            },
          },
          // {
          //   name: "课程目标",
          //   borderColor: Highcharts.getOptions().colors[2],
          //   data: [
          //     {
          //       color: Highcharts.getOptions().colors[2],
          //       radius: "66%",
          //       innerRadius: "66%",
          //       y: this.grapheDetails.c.value,
          //     },
          //   ],
          // },
        ],
      },
    };
  },
  created: function () {},
  mounted() {
    console.log("图表数据：", this.grapheDetails);
    this.renderChart();
    this.autoRefreshToolTip();
  },
  methods: {
    showFinishAnimation() {
      this.inAnimation = true;
      this.zzzShort();
    },
    renderChart() {
      this.chart = new Highcharts.chart("pieChart", this.options);
    },
    autoRefreshToolTip() {
      if (this.showInterVal) {
        clearInterval(this.showInterVal);
      }
      let count = 1;
      let arr = Object.keys(this.grapheDetails);
      this.showInterVal = setInterval(() => {
        if (arr.length > 0) {
          let show = count % arr.length;
          this.showIndex = arr[show];
        }
        count += 1;
      }, 8000);
    },
    chartclickA() {
      this.showIndex = "a";
      console.info("图表 a 被点击");
      this.autoRefreshToolTip();
    },
    chartclickB() {
      this.showIndex = "b";
      console.info("图表 b 被点击");
      this.autoRefreshToolTip();
    },
  },
};
</script>

<style lang="scss" scoped>
//::v-deep .highcharts-container {
//  z-index: 1 !important;
//}
.chart {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  #pieChart {
    width: 100%;
    height: 100%;
    //z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
  }

  .tool-tip {
    margin: -0.3rem 0 0 0;
    width: 8rem;
    height: 8rem;
    //background-color: #7a7a7a;
    border-radius: 50%;
    //z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .group-success {
      margin-top: -0.6rem;
      width: 5rem;
      height: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .finish-img {
        animation-name: upAnimation;
        transform-origin: center bottom;
        animation-duration: 2s;
        animation-fill-mode: both;
        animation-iteration-count: 1;
        animation-delay: 0s;
        @keyframes upAnimation {
          0% {
            transform: rotate(0deg);
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          }

          10% {
            transform: rotate(-12deg);
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          }

          20% {
            transform: rotate(12deg);
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          }

          28% {
            transform: rotate(-10deg);
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          }

          36% {
            transform: rotate(10deg);
            transition-timing-function: cubic-bezier(0.755, 0.5, 0.855, 0.06);
          }

          42% {
            transform: rotate(-8deg);
            transition-timing-function: cubic-bezier(0.755, 0.5, 0.855, 0.06);
          }

          48% {
            transform: rotate(8deg);
            transition-timing-function: cubic-bezier(0.755, 0.5, 0.855, 0.06);
          }

          52% {
            transform: rotate(-4deg);
            transition-timing-function: cubic-bezier(0.755, 0.5, 0.855, 0.06);
          }

          56% {
            transform: rotate(4deg);
            transition-timing-function: cubic-bezier(0.755, 0.5, 0.855, 0.06);
          }

          60% {
            transform: rotate(0deg);
            transition-timing-function: cubic-bezier(0.755, 0.5, 0.855, 0.06);
          }

          100% {
            transform: rotate(0deg);
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          }
        }
      }
      .word {
        font-size: 1rem;
        color: #505050;
        font-weight: 700;
      }
    }
    .group {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .target {
        font-size: 1.1rem;
        color: #505050;
        font-weight: 700;
      }
      .value {
        font-family: dosisbold, sans-serif;
        font-size: 2.1rem;
        font-weight: 700;
      }
    }
  }
}

.tooltip-enter-active,
.tooltip-leave-active {
  transition: all 1s ease;
}
.tooltip-enter,
.tooltip-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
