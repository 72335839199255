<template>
  <v-slide-y-transition>
    <div v-on:keyup.enter="directSearch">
      <v-text-field
        solo
        hide-details
        label="查单词"
        placeholder="请输入想查找的单词"
        prepend-inner-icon="mdi-arrow-left"
        @click:prepend-inner="hideSearch"
        append-icon="mdi-magnify"
        @click:append="directSearch"
        v-model="searchWord"
        class="input-search rounded-lg"
        autocomplete="off"
        ref="searchInput"
      >
      </v-text-field>
      <v-progress-linear
        class="input-search progress-linear"
        :active="isLoading"
        :indeterminate="true"
        height="2"
        absolute
        color="deep-purple accent-1"
      ></v-progress-linear>

      <v-list
        v-if="searchResults.length > 0"
        class="rec-word-list rounded-lg"
        max-height="calc(80vh - 2rem)"
        dense
        rounded
        elevation="1"
      >
        <v-list-item
          v-for="(item, index) in searchResults"
          :key="index"
          @click="itemClick(item)"
          class="list-item"
        >
          <v-list-item-title class="item"
            ><span>{{ item.word }}</span>
            <span v-show="item.phonetic"
              ><van-tag>/ {{ item.phonetic }} /</van-tag></span
            ><span class="translation" v-show="item.translation">{{
              formatTranslate(item.translation)
            }}</span></v-list-item-title
          >
        </v-list-item>
      </v-list>
    </div>
  </v-slide-y-transition>
</template>
<script>
import $api from "@/api/api";
import { Tag } from "vant";
import { isEmpty } from "@/utils/common";
import { buttonVibrate } from "@/mixin/mixin";

export default {
  name: "searchBox",
  mixins: [buttonVibrate],
  components: {
    vanTag: Tag,
  },
  computed: {
    formatTranslate() {
      return function (text) {
        if (!isEmpty(text)) {
          return text.replace(/\n/g, " ");
        }
        return "";
      };
    },
  },
  data() {
    return {
      isLoading: false,
      searchWord: "",
      showSelect: true,
      searchResults: [],
      inputTimer: null,
    };
  },
  watch: {
    searchWord: "inputHandle",
  },
  methods: {
    hideSearch() {
      this.zzzShort();
      this.$emit("hideSearch");
    },
    directSearch() {
      this.$refs.searchInput.blur();
      if (
        this.searchWord.trim() !== "" &&
        /^[A-Za-z ]+$/g.test(this.searchWord)
      ) {
        this.zzzShort();
        this.$router.push({
          path: "/word_detail",
          query: {
            word: this.searchWord,
          },
        });
      } else {
        this.zzzLong();
        this.$toast("查询内容必须为单词！");
      }
    },
    //进入时聚焦
    doFocus() {
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },
    //点击搜索项
    itemClick(item) {
      this.zzzShort();
      this.searchWord = item.word;
      this.$refs.searchInput.blur();
      this.$router.push({
        path: "/word_detail",
        query: {
          word: this.searchWord,
          id: item.id,
        },
      });
    },
    inputHandle(searchWord) {
      if (searchWord.trim() !== "" && /^[A-Za-z ]+$/g.test(searchWord)) {
        clearTimeout(this.inputTimer); //防抖
        this.inputTimer = setTimeout(() => {
          console.info("搜索关键词：", searchWord);
          this.getEntity(searchWord);
        }, 200);
      }
    },
    getEntity(searchWord) {
      this.isLoading = true;
      console.log(this.isLoading);
      // 请求接口更新 searchResults 数据
      if (isEmpty(searchWord)) {
        return;
      }
      let getData = {
        word: searchWord,
        limit: 25,
      };
      $api
        .getRecommandWordList(getData)
        .then((ret) => {
          let res = ret.data;
          if (res.code === 0) {
            this.showSelect = true;
            this.searchResults = res.data;
          } else {
            this.$toast.error(res.msg);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.input-search {
  width: calc(100% - 1.4rem);
  margin: 0 auto !important;
}

.progress-linear {
  position: relative !important;
  width: calc(100% - 20px - 1rem);
  align-items: center;
  margin: 0 auto !important;
}

.rec-word-list {
  width: calc(100% - 1.4rem);
  margin: 0 auto !important;
  border: 1px solid #eee !important;
  box-shadow: 1px 1px #505050;
  overflow: auto;

  .list-item {
    .item {
      > span {
        margin-right: 10px;
      }

      .translation {
        color: #888888;
      }
    }
  }
}
</style>
