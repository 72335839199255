<template>
  <v-img class="svg-icon" :src="url" :height="height" :width="width"></v-img>
</template>

<script>
export default {
  name: "svgIcon",
  props: {
    url: {
      type: String,
      default() {
        return "";
      },
    },
    width: {
      type: String,
      default() {
        return "3rem";
      },
    },
    height: {
      type: String,
      default() {
        return "3rem";
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.svg-icon {
}
</style>
