import axios from "axios";
import router from "@/router";
import store from "@/store";
import _this from "@/main.js";
import { isEmpty } from "@/utils/common";

//默认请求地址
// axios.defaults.baseURL = "http://localhost:8080/";
axios.defaults.baseURL = process.env["VUE_APP_BASE_URL"];
//请求超时时间
axios.defaults.timeout = 10000;

//请求拦截
axios.interceptors.request.use(
  (config) => {
    if (store.state.auth && store.state.auth.token !== null) {
      //返回 token
      config.headers["token"] = store.state.auth.token;
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//响应拦截器
axios.interceptors.response.use(
  (response) => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是2开头的的情况
  // 这里可以跟你们的后台开发人员协商好统一的错误状态码
  // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
  (error) => {
    if (error.response !== undefined && error.response.status) {
      console.log("有状态码：" + error.response.status);
      let msg = error.response.data.msg;
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作
        case 401:
          console.log("用户认证失败：" + msg);
          //弹出弹窗提示登录失效
          if (isEmpty(msg)) {
            _this.$toast("您需要重新登录 或者 登录已经过期");
          } else {
            _this.$toast(msg);
          }
          // 这里写清除token的代码
          setTimeout(() => {
            store.commit("clearLogin");
          }, 200);
          //这里写路由跳转代码
          if (router.currentRoute.path !== "/auth/login") {
            router
              .replace({
                path: "/auth/login",
                query: { redirect: router.currentRoute.fullPath }, //登录成功后跳入浏览的当前页面
              })
              .catch((e) => {
                console.log(e);
              });
          }
          break;
        case 404:
          _this.$toast("404 接口没找到：" + msg);
          break;
        case 405:
          _this.$toast("405 您的权限不足：" + msg);
          break;
        case 500:
          _this.$toast("500 内部错误：" + msg);
          break;
        default:
          _this.$toast("其他内部错误：" + msg);
      }
      return Promise.reject(error);
    } else {
      console.log("无状态码错误", error);
      _this.$toast("连接到服务器异常");
      if (error.response) {
        return Promise.reject(error);
      }
      return Promise.reject("offline");
    }
  }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */

export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */

export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
