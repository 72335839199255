import { mapGetters } from "vuex";
import { isEmpty } from "@/utils/common";

export const setSystemColor = {
  computed: {
    ...mapGetters({
      isApp: "isApp",
      auth: "auth",
      appThemeConfig: "appThemeConfig",
    }),
  },
  methods: {
    //设置底栏颜色
    setSystemFootColor(systemFootColor) {
      if (this.plusReady && this.isApp && systemFootColor) {
        let plus = window.plus;
        if (plus) {
          console.log(">>> 设置appFooter颜色 >>>", systemFootColor);
          if (plus.os.name !== "iOS") {
            console.log("------------Android系统------------");
            //切换安卓虚拟按键颜色
            let color = plus.android.newObject("android.graphics.Color");
            let ac = plus.android.runtimeMainActivity();
            let bgc = plus.android.invoke(color, "parseColor", systemFootColor);
            let win = plus.android.invoke(ac, "getWindow");
            plus.android.invoke(win, "setNavigationBarColor", bgc);
          } else {
            console.log("------------IOS系统------------");
            // plus.navigator.setStatusBarBackground(systemFootColor);
          }
        }
      }
    },
    //设置状态栏文字颜色
    setSystemStatusBarStyle(mode) {
      if (this.plusReady && this.isApp) {
        let plus = window.plus;
        if (plus) {
          console.log(">>> 设置状态栏文字模式 >>>", mode);
          plus.navigator.setStatusBarStyle(mode);
          console.log("状态栏文字颜色：", mode);
        }
      }
    },
  },
};

export const avatrTool = {
  computed: {
    ...mapGetters({
      auth: "auth",
    }),
    userAvatar() {
      if (!this.auth.userdetail) {
        return require("@/assets/uiimg/default-user.png");
      } else {
        if (isEmpty(this.auth.userdetail.avatar)) {
          if (!!this.auth.userdetail.sex && this.auth.userdetail.sex === 0) {
            return require("@/assets/uiimg/default-user-girl.png");
          }
          return require("@/assets/uiimg/default-user.png");
        }
        return this.auth.userdetail.avatar;
      }
    },
  },
};

export const userInfo = {
  computed: {
    ...mapGetters({
      auth: "auth",
    }),
    userFriendName() {
      if (!isEmpty(this.auth.userdetail) && this.auth.userdetail.nickName) {
        return this.auth.userdetail.nickName;
      }
      if (!isEmpty(this.auth.userdetail) && this.auth.userdetail.userName) {
        return this.auth.userdetail.userName;
      }
      return "您未登录";
    },
  },
};

export const nosleep = {
  computed: {
    ...mapGetters({
      touched: "touched",
      isApp: "isApp",
    }),
  },
  data() {
    return {
      noSleep: new this.$NoSleep(),
    };
  },
  methods: {
    enableNoSleep() {
      try {
        if (this.isApp) {
          // 防止睡眠
          let plus = window.plus;
          plus.device.setWakelock(true);
        }
        if (this.touched) {
          this.noSleep.enable();
        }
      } catch (e) {
        console.log("发生异常:" + e);
      }
    },
    disableNoSleep() {
      try {
        if (this.isApp) {
          // 防止睡眠
          let plus = window.plus;
          plus.device.setWakelock(false);
        }
        if (this.touched) {
          this.noSleep.disable();
        }
      } catch (e) {
        console.log("发生异常:" + e);
      }
    },
  },
};

export const buttonVibrate = {
  computed: {
    ...mapGetters({
      touched: "touched",
      isApp: "isApp",
    }),
  },
  methods: {
    zzzMid() {
      if (this.isApp) {
        let plus = window.plus;
        if (plus) {
          plus.device.vibrate(50);
          console.info("设备原生标准震动！");
        }
      } else {
        if (this.touched && navigator.vibrate) {
          navigator.vibrate(50);
          console.log("设备标准震动！");
        } else {
          console.log("设备不支持震动！");
        }
      }
    },
    zzzShort() {
      if (this.isApp) {
        let plus = window.plus;
        if (plus) {
          plus.device.vibrate(5);
          console.info("设备原生短震动！");
        }
      } else {
        if (this.touched && navigator.vibrate) {
          navigator.vibrate(10);
          console.log("设备短震动！");
        } else {
          console.log("设备不支持震动！");
        }
      }
    },
    zzzLong() {
      if (this.isApp) {
        let plus = window.plus;
        plus.device.vibrate(200);
        console.log("设备原生长震动！");
      } else {
        if (this.touched && navigator.vibrate) {
          navigator.vibrate(200);
          console.log("设备长震动！");
        } else {
          console.log("设备不支持震动！");
        }
      }
    },
  },
  created() {
    navigator.vibrate =
      navigator.vibrate ||
      navigator.webkitVibrate ||
      navigator.mozVibrate ||
      navigator.msVibrate;
  },
};
