import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { VSnackbar, VBtn, VIcon, VChip } from "vuetify/lib";
import VuetifyToast from "vuetify-toast-snackbar-ng";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
    VChip,
  },
});
Vue.use(VuetifyToast, {
  x: "center", // default
  y: "bottom", // default
  color: "#505050", // default
  icon: "mdi-information-outline",
  iconColor: "", // default
  classes: ["pop-up"],
  timeout: 3500, // default
  dismissable: true, // default
  multiLine: false, // default
  vertical: false, // default
  queueable: false, // default
  showClose: true, // default
  closeText: "", // default
  closeIcon: "mdi-close", // default
  closeColor: "", // default
  slot: [], //default
  shorts: {
    custom: {
      color: "purple",
    },
  },
  property: "$toast", // default
});

export default new Vuetify({
  icons: {
    iconfont: "fa",
  },
});
