import { render, staticRenderFns } from "./App.vue?vue&type=template&id=534f8a92&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.9.8_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/.pnpm/vuetify-loader@1.7.3_cfe6260f4a77386d492c4e564aa08ca3/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VScrollYTransition } from 'vuetify/lib/components/transitions';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VApp,VFadeTransition,VMain,VOverlay,VProgressCircular,VScrollYTransition,VSheet})
