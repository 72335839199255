import Vue from "vue";
import VueRouter from "vue-router";
import homeIndex from "@/views/home/homeIndex";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "homeIndex",
    component: homeIndex,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import("../views/auth/authIndex"),
    redirect: () => {
      return "/auth/login";
    },
    meta: {
      keepAlive: true,
      noTransition: true,
      hideSysBar: true,
    },
    children: [
      {
        path: "login",
        name: "doLogin",
        component: () => import("../views/auth/loginPanel"),
        meta: {
          needNotLogin: true,
          keepAlive: false,
          noTransition: true,
          hideSysBar: true,
        },
      },
      {
        path: "register",
        name: "doReg",
        component: () => import("../views/auth/regPanel"),
        meta: {
          needNotLogin: true,
          keepAlive: false,
          noTransition: true,
          hideSysBar: true,
        },
      },
    ],
  },
  {
    path: "/wordtable",
    name: "wordTable",
    component: () => import("../views/wordTable/wordTableIndex"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/classtable",
    name: "classTable",
    component: () => import("../views/classTable/classTableIndex"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/learnword",
    name: "LearnWord",
    component: () => import("../views/learnWord/learnWordIndex"),
    meta: {
      keepAlive: true,
      hideSysBar: true,
    },
  },
  {
    path: "/learnclass",
    name: "LearnClass",
    component: () => import("../views/learnClass/learnClassIndex"),
    meta: {
      keepAlive: true,
      hideSysBar: true,
    },
  },
  {
    path: "/analyse",
    name: "Analyse",
    component: () => import("../views/analyseStastics/analyseStasticsIndex"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/focus",
    name: "focusMode",
    component: () => import("../views/focus/focusIndex"),
    meta: {
      keepAlive: true,
      hideSysBar: true,
    },
  },
  {
    path: "/word_detail",
    name: "wordDetail",
    component: () => import("../views/wordDetail/wordDetailIndex"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/reader",
    name: "Reader",
    component: () => import("../views/reader/readerPage"),
    meta: {
      keepAlive: true,
      hideSysBar: true,
    },
  },
  {
    path: "/class_detail",
    name: "classDetail",
    component: () => import("../views/classDetail/classDetailIndex"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/read",
    name: "Read",
    component: () => import("../views/read/readIndex"),
    meta: {
      keepAlive: true,
    },
    redirect: () => {
      return "/read/text";
    },
    children: [
      {
        path: "text",
        name: "readText",
        component: () => import("../views/read/readText"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "all",
        name: "readAll",
        component: () => import("../views/read/readAll"),
        meta: {
          keepAlive: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  //所有设置meta的，needlogin为true的都守卫
  if (to.meta.needNotLogin === true) {
    next();
  } else {
    let token = store.state.auth.token;
    // let auth = localStorage.getItem("auth");
    if (token == null) {
      console.log("路由守卫检测未登录");
      next("/auth/login");
    } else {
      next();
    }
  }
});

export default router;
