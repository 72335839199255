<template>
  <v-app
    :style="`background-color: ${appThemeConfig.appBgColor};height:${screenHeight}px;`"
  >
    <app-system-bar v-show="isApp && !$route.meta.hideSysBar"></app-system-bar>
    <app-drawer v-if="!$route.path.match(/auth*/)"></app-drawer>
    <v-sheet id="main" class="overflow-y-auto hide-scroll">
      <!-- 根据应用组件来调整你的内容 -->
      <v-main
        :style="`height:${
          isApp && !$route.meta.hideSysBar
            ? screenHeight - systemBarHeight
            : screenHeight
        }px;`"
      >
        <!-- 如果使用 vue-router -->
        <router-view v-if="$route.meta.noTransition"></router-view>
        <transition name="slide-fade">
          <keep-alive
            ><router-view
              v-if="!$route.meta.noTransition && $route.meta.keepAlive"
            ></router-view
          ></keep-alive>
        </transition>
        <transition name="slide-fade"
          ><router-view
            v-if="!$route.meta.noTransition && !$route.meta.keepAlive"
          ></router-view
        ></transition>
      </v-main>
    </v-sheet>
    <v-scroll-y-transition
      ><app-tab v-if="showSysAppTab" @switchTab="switchTab"></app-tab
    ></v-scroll-y-transition>
    <v-overlay
      :value="appLoading.show"
      dark
      class="overlay"
      :color="appLoading.theme"
      :opacity="appLoading.opacity"
    >
      <v-progress-circular
        class="overlay-icon"
        indeterminate
        size="55"
        :color="appLoading.color"
      ></v-progress-circular>
      <v-fade-transition
        ><div class="overlay-text" :style="`color : ${appLoading.color}`">
          加载中
        </div></v-fade-transition
      >
    </v-overlay>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { setSystemColor } from "@/mixin/mixin";
import { purpleTheme } from "@/themes/purple/purple";
import { colorfulTheme } from "@/themes/colorful/colorful";
import { checkBrowser, deepClone } from "@/utils/common";
import $api from "@/api/api";
import $uniPush from "@/plus/uniPush";
import AppSystemBar from "@/components/app/appSystemBar";

export default {
  name: "App",
  components: {
    AppSystemBar,
    AppTab: () => import("@/components/app/appTab"),
    AppDrawer: () => import("@/components/app/appDrawer"),
  },
  mixins: [setSystemColor],
  computed: {
    ...mapGetters({
      auth: "auth",
      appTheme: "appTheme",
      appThemeConfig: "appThemeConfig",
      appLoading: "appLoading",
      screenHeight: "screenHeight",
      screenWidth: "screenWidth",
      showSysAppTab: "showSysAppTab",
      appTabIndex: "appTabIndex",
      isApp: "isApp",
      systemBarHeight: "systemBarHeight",
      appSystemBarColor: "appSystemBarColor",
    }),
  },
  data() {
    return {
      tempHeight: 0,
      renewInterval: null,
      analyseTimeInterval: null,
    };
  },
  methods: {
    partCom() {
      //使用说明：例如设计稿的宽度是 640px，如果一个元素的实际宽高都为 200px，那么如果使用 rem 为单位，宽高便都为 2rem。
      //rem = px / 100。
      let html = document.documentElement;
      let size = document.body.clientWidth / 25;
      if (size <= 20) {
        html.style.fontSize = size + "px";
      } else if (document.body.clientWidth > 750) {
        html.style.fontSize = "16px";
      } else {
        html.style.fontSize = "20px";
      }
    },
    screenfit() {
      setTimeout(() => {
        let newWidth = document.body.clientWidth;
        let newHeight = document.documentElement.clientHeight;
        let widthChange = false;
        if (!this.screenWidth || this.screenWidth !== newWidth) {
          this.$store.commit("setScreenWidth", newWidth);
          this.partCom();
          widthChange = true;
          console.info("@@宽度调整");
        }

        if (!this.screenHeight) {
          this.tempHeight = newHeight;
          this.$store.commit("setScreenHeight", newHeight);
        } else {
          if (this.screenHeight - newHeight > 150) {
            this.tempHeight = newHeight;
            if (widthChange) {
              this.$store.commit("setScreenHeight", newHeight);
              console.info("@@高度调整");
            } else {
              console.info("@@@@软键盘弹出");
            }
          } else if (newHeight - this.tempHeight > 150) {
            if (widthChange) {
              this.$store.commit("setScreenHeight", newHeight);
              console.info("@@高度调整");
            } else {
              console.info("@@@@软键盘收起");
              setTimeout(() => {
                this.tempHeight = newHeight;
              }, 200);
            }
          } else {
            this.tempHeight = newHeight;
            if (this.screenHeight !== newHeight) {
              this.$store.commit("setScreenHeight", newHeight);
              console.info("@@高度调整");
            }
          }
        }

        console.info("屏幕尺寸：", this.screenWidth + "*" + this.screenHeight);
      }, 200);
    },
    switchTab(tab) {
      this.$store.dispatch("setShowSysAppTab", true);
      this.$store.dispatch("setAppTabIndex", tab);
      // this.$store.dispatch(
      //   "setAppSystemBarColor",
      //   this.appThemeConfig.frameWorkColorList[tab].systemBarColor
      // );
      // this.setSystemFootColor(
      //   this.appThemeConfig.frameWorkColorList[tab].footerBarColer
      // );
    },
    setThemeColor() {
      switch (this.appTheme) {
        case "purple":
          this.$store.commit("setAppThemeConfig", purpleTheme);
          break;
        case "colorful":
          this.$store.commit("setAppThemeConfig", colorfulTheme);
          break;
      }
    },
    setAppMetaColor(color) {
      console.info("设置浏览器主题色", color);
      document
        .querySelector('meta[name="theme-color"]')
        .setAttribute("content", color);
    },
    doRenew() {
      if (this.auth.skey) {
        let timeStamp = parseInt(new Date().getTime()) / 1000;
        let remainTime = parseInt(this.auth.expire) - timeStamp;
        if (remainTime > 24 * 3600) {
          // console.info("剩余时间： ", remainTime / 24 / 3600);
          return;
        }
        let postData = {
          skey: this.auth.skey,
        };
        $api.renewAuth(postData).then((ret) => {
          let res = ret.data;
          if (res.code === 0) {
            let newAuth = deepClone(this.auth);
            newAuth.token = res.data.token;
            newAuth.expire = res.data.expire;
            this.$store.commit("doLongLogin", newAuth);
            console.log(">>>> auth >>> 认证已刷新！");
          }
        });
      }
    },
    analyseTime() {
      if (this.auth.skey) {
        let type = null;
        if (this.$route.path.match(/^\/learnword/i)) {
          type = 0;
        }
        if (this.$route.path.match(/^\/reader/i)) {
          type = 1;
        }
        if (type === null) {
          return;
        }
        let postData = {
          type: type,
        };
        $api.analyseTime(postData).then((ret) => {
          let res = ret.data;
          if (res.code === 0) {
            console.info("同步学习时间", res.data.sql);
          }
        });
      }
    },
  },
  created() {
    // 响应式尺寸适配
    this.partCom();
    this.screenfit();
    this.setThemeColor();
    window.addEventListener("resize", this.screenfit, false);
    // 点击监听
    let clickListener = () => {
      this.$store.dispatch("setTouched");
      document.removeEventListener("click", clickListener, false);
    };
    document.addEventListener("click", clickListener, false);

    // 状态栏主题色初始化
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", this.appSystemBarColor);
    this.onPlusReady(() => {
      let plus = window.plus;
      if (plus) {
        if (plus.os.name !== "iOS") {
          let main = plus.android.runtimeMainActivity();
          //为了防止快速点按返回键导致程序退出重写quit方法改为隐藏至后台
          plus.runtime.quit = function () {
            console.log("退出App，进入后台");
            main.moveTaskToBack(false);
          };
        }
        this.$store.dispatch("setIsApp", true);
        this.$store.dispatch(
          "setSystemBarHeight",
          plus.navigator.getStatusbarHeight()
        );
        document.addEventListener(
          "pause",
          () => {
            this.$store.dispatch("setAppStatus", 0);
            console.log("****应用进入后台");
            //从前台切换到后台
          },
          false
        );

        document.addEventListener(
          "resume",
          () => {
            this.$store.dispatch("setAppStatus", 1);
            console.log("****应用被唤醒");
            //从后台切换到前台
          },
          false
        );
        // 监听uistylechange消息(深色模式)
        document.addEventListener(
          "uistylechange",
          function () {
            let style = plus.navigator.getUiStyle();
            console.log("current ui style: " + style);
          },
          false
        );
        this.setSystemFootColor(
          this.appThemeConfig.frameWorkColorList[1].footerBarColer
        );
        this.setSystemStatusBarStyle(
          this.appThemeConfig.dark ? "light" : "dark"
        );
        //处理返回键
        let first = null;
        let webview = plus.webview.currentWebview(); //获取当前页面的webview对象
        plus.key.addEventListener(
          "backbutton",
          () => {
            webview.canBack((e) => {
              // canBack函数用于查询Webview窗口是否可后退
              if (e.canBack && this.$route.path !== "/") {
                //判断是否可以后退
                webview.back(); // 调用当前webview的后退
              } else {
                // else代码块表示不能后退，也就意味着回退到首页了
                if (!first) {
                  //连按两次退出程序的功能实现
                  first = new Date().getTime();
                  //plus.nativeUI.toast('再按一次退出应用');
                  this.$toast("再按一次退出应用");
                  setTimeout(function () {
                    first = null;
                  }, 1000);
                } else {
                  if (new Date().getTime() - first < 1000) {
                    plus.runtime.quit();
                  }
                }
              }
            });
          },
          false
        );
        // unipush开始加载
        let cid = $uniPush.getPushCid();
        console.log("！！！pushCID：", cid);
        $uniPush.initListen();
        //关闭启动界面
        setTimeout(() => {
          plus.navigator.closeSplashscreen();
        }, 150);
        console.log("plus加载成功");
      } else {
        this.$store.dispatch("setIsApp", false);
      }
    });
    this.$store.dispatch("setBrowser", checkBrowser());
  },
  mounted() {
    this.renewInterval = setInterval(() => {
      this.doRenew();
    }, 30000);
    this.analyseTimeInterval = setInterval(() => {
      this.analyseTime();
    }, 1000 * 60);
  },
  destroyed() {
    clearInterval(this.renewInterval);
    clearInterval(this.analyseTimeInterval);
  },
  watch: {
    appSystemBarColor(val) {
      if (this.appLoading.show) {
        setTimeout(() => {
          this.setAppMetaColor(val);
        }, this.appLoading.time - 650);
      } else {
        this.setAppMetaColor(val);
      }
    },
    appLoading: {
      handler(newValue) {
        if (newValue.show) {
          this.setAppMetaColor(newValue.theme);
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
//#__vconsole {
//  display: none;
//}
//类名切换显示/隐藏
.show-console {
  display: block !important;
}
html {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  height: 100%;
}
body {
  height: 100%;
}
#main {
  height: 100%;
}
.v-application--wrap {
  min-height: unset !important;
}
#app {
  font-family: sans-serif;
  letter-spacing: 0.04rem;
  overflow: hidden;
}
.cut-words {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.none-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.none-overflow {
  overflow: hidden;
}
.v-menu__content {
  border-radius: 7px !important;
}
.overlay {
  text-align: center;
  .overlay-icon {
    margin-top: -3rem;
  }
  .overlay-text {
    color: #4c62cc;
    margin: 1rem 0;
    font-weight: 300;
    letter-spacing: 0.2rem;
    font-size: 0.9rem;
  }
}
.btn-disabled {
  user-select: none;
  cursor: default;
  pointer-events: none;
}
.hide-scroll::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.show-scroll::-webkit-scrollbar {
  width: 4px !important;
  height: 8px !important;
  border-radius: 50px;
  opacity: 0;
}
.show-scroll::-webkit-scrollbar-thumb {
  width: 4px !important;
  height: 8px !important;
  border-radius: 50px;
  background-color: rgba(124, 124, 124, 0.9);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0) !important;
}
.pop-up {
  z-index: 4 !important;
}
input {
  letter-spacing: 0.1rem !important;
}
.v-btn {
  font-weight: 400;
}
.v-btn:before {
  background-color: currentColor;
  border-radius: inherit;
  bottom: 0;
  color: inherit;
  content: "";
  left: 0;
  opacity: 0 !important;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: unset !important;
}
.v-snack {
  bottom: 1.5rem !important;
}
.van-sticky--fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5 !important;
}
.dplayer-menu {
  .dplayer-menu-item {
    &:nth-last-child(1),
    &:nth-last-child(2) {
      display: none;
    }
  }
}
/*
  页面切换过渡动画
*/
.slide-fade-enter-active {
  transition: all 0.5s ease;
  transition-delay: 0.15s;
}

.slide-fade-leave-active {
  transition: all 0.15s ease;
  transition-delay: 0s;
}

.slide-fade-enter {
  transform: translateX(5rem);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(5rem);
  opacity: 0;
}
</style>
