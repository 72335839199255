<template>
  <div :class="appTheme">
    <div id="home" class="none-select">
      <home-app-head ref="appHead" @showSearch="showSearch"></home-app-head>
      <search-box
        ref="searchBox"
        v-show="onSearch"
        class="search"
        @hideSearch="hideSearch"
      ></search-box>

      <v-fade-transition>
        <div v-show="onSearch" class="search-mask">
          <div
            class="mask-content"
            :style="isApp ? `padding-top: ${systemBarHeight}px` : ''"
          >
            <div class="history">
              <v-chip
                class="word"
                small
                close
                @click:close="removeWordHistory(item)"
                @click="quickSearch(item)"
                v-for="(item, index) in wordSearchHistory"
                :key="index"
              >
                {{ item }}
              </v-chip>
            </div>
            <div class="nodata" @click="hideSearch">
              <div v-show="onSearch && isEmpty($refs.searchBox.searchResults)">
                暂无结果
              </div>
            </div>
          </div>
        </div>
      </v-fade-transition>

      <div
        class="home-content"
        :style="`background-color: ${appThemeConfig.appBgColor}; margin-top: ${
          isApp ? systemBarHeight : 0
        }px`"
      >
        <div class="home-back-ground-a"></div>
        <div class="home-back-ground-b"></div>
        <div class="svg-wave d-flex d-md-none d-lg-none d-xl-none">
          <svg-wave
            :width="1000"
            :height="500"
            :bulgeDistance="80"
            :lineDistance="30"
          ></svg-wave>
        </div>
        <div class="content">
          <div class="welcome">
            <v-btn class="my-avatar" icon @touchstart="zzzMid">
              <v-avatar :elevation="1" size="43" @click="openDrawer">
                <img :src="userAvatar" alt="John" />
              </v-avatar>
            </v-btn>

            <div class="welcome-text">
              <div class="name">
                {{ userFriendName }}
              </div>
              <div class="word">欢迎，</div>
            </div>
            <div class="welcome-search">
              <v-btn icon @click="showSearch" @touchstart="zzzMid">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </div>
          </div>

          <v-container class="home-cards">
            <v-row no-gutters>
              <v-col cols="12" md="5" lg="4">
                <pie-chart
                  class="main-chart"
                  :grapheDetails="grapheDetails"
                ></pie-chart>

                <div class="text-details">
                  <div class="text-details-group">
                    <v-btn
                      plain
                      rounded
                      class="text-details-value hide-scroll"
                      @touchstart="zzzMid"
                      @click="doEncourage('time')"
                    >
                      {{ learnToday.wordsTime + learnToday.classTime }}
                    </v-btn>
                    <div class="text-details-desc">
                      分钟
                      <v-icon>far fa-clock</v-icon>
                    </div>
                  </div>
                  <div class="text-details-group">
                    <v-btn
                      plain
                      rounded
                      class="text-details-value hide-scroll"
                      @touchstart="zzzMid"
                      @click="doEncourage('word')"
                    >
                      {{ learnToday.wordsNew + learnToday.wordsReview }}
                    </v-btn>
                    <div class="text-details-desc">
                      单词
                      <v-icon>fa fa-align-justify</v-icon>
                    </div>
                  </div>
                  <div class="text-details-group">
                    <v-btn
                      plain
                      rounded
                      class="text-details-value hide-scroll"
                      @touchstart="zzzMid"
                      @click="doEncourage('class')"
                    >
                      {{ learnToday.classCount }}
                    </v-btn>

                    <div class="text-details-desc">
                      课程
                      <v-icon>fa fa-border-all</v-icon>
                    </div>
                  </div>
                </div>
                <div class="set-gole">
                  <v-btn
                    class="set-gole-btn"
                    elevation="1"
                    depressed
                    rounded
                    @touchstart="zzzShort"
                    @touchend="zzzMid"
                    @click="setGole"
                    >查看并调整目标
                    <gole-dialog
                      ref="goleDialog"
                      @refreshCircle="refreshCircle"
                    ></gole-dialog>
                  </v-btn>
                </div>
              </v-col>

              <!-- 卡片列表开始 -->
              <v-col cols="12" md="7" lg="8">
                <v-row>
                  <v-col cols="12" md="12" lg="6">
                    <v-card
                      v-ripple
                      title="card-title"
                      class="detail-card rounded-lg"
                      elevation="1"
                      style="overflow: hidden"
                      @click="goLearnWord"
                    >
                      <v-card-title class="card-title">单词学习</v-card-title>
                      <v-card-subtitle>复习并学习新单词</v-card-subtitle>
                      <div class="detail-card-content">
                        <div class="detail-card-details">
                          <div class="today-left">
                            今日已学
                            <span class="today-center-l">{{
                              learnToday.wordsReview + learnToday.wordsNew
                            }}</span
                            ><span class="today-center-r"
                              >/{{
                                userPreferrence.gole.newWordGole +
                                userPreferrence.gole.reviewWordGole
                              }}</span
                            >
                            <span class="today-right ml-1">词</span>
                          </div>
                          <div class="have-learned">
                            已掌握
                            <span class="have-learned-val">{{
                              total.knownWords
                            }}</span>
                            <span class="have-learned-r ml-1">词</span>
                          </div>
                        </div>

                        <v-progress-circular
                          :value="wordProgress"
                          :width="3"
                          class="detail-card-progress"
                          style="width: 3.5rem; height: 3.5rem"
                          color="#f1915b"
                        >
                          <v-scroll-x-transition>
                            <v-icon
                              v-show="learnToday.wordsBingo"
                              color="#f1d178"
                            >
                              mdi-check
                            </v-icon>
                          </v-scroll-x-transition>
                        </v-progress-circular>
                      </div>
                      <v-card-actions>
                        <v-btn
                          rounded
                          small
                          plain
                          class="right-button btn-disabled"
                          @click="zzzMid"
                          >进入记忆
                          <v-icon right> mdi-play</v-icon>
                        </v-btn>
                      </v-card-actions>
                      <div class="left-icon">
                        <svg-icon
                          :url="
                            require('@/assets/svgimgs/undraw_memory_storage_reh0.svg')
                          "
                          width="5rem"
                          height="5rem"
                        ></svg-icon>
                      </div>
                    </v-card>
                  </v-col>

                  <!-- 第二卡片 -->
                  <v-col cols="12" md="12" lg="6">
                    <v-card
                      v-ripple
                      title="card-title"
                      class="detail-card rounded-lg"
                      elevation="1"
                      style="overflow: hidden"
                      @click="goLearnClass"
                    >
                      <v-card-title class="card-title">课程学习</v-card-title>
                      <v-card-subtitle>外刊阅读和视频课程</v-card-subtitle>
                      <div class="detail-card-content">
                        <div class="detail-card-details">
                          <div class="today-left">
                            今日已学
                            <span class="today-center-l">{{
                              learnToday.classCount
                            }}</span
                            ><span class="today-center-r"
                              >/{{ userPreferrence.gole.classGole }}</span
                            >
                            <span class="today-right ml-1">篇</span>
                          </div>
                          <div class="have-learned">
                            已学习
                            <span class="have-learned-val">{{
                              total.learnedPassage
                            }}</span>
                            <span class="have-learned-r ml-1">篇</span>
                          </div>
                        </div>

                        <v-progress-circular
                          :value="classProgress"
                          :width="3"
                          class="detail-card-progress"
                          style="width: 3.5rem; height: 3.5rem"
                          color="#f1915b"
                        >
                          <v-scroll-x-transition>
                            <v-icon
                              v-show="learnToday.classBingo"
                              color="#f1d178"
                            >
                              mdi-check
                            </v-icon>
                          </v-scroll-x-transition>
                        </v-progress-circular>
                      </div>
                      <v-card-actions>
                        <v-btn
                          rounded
                          small
                          plain
                          class="right-button btn-disabled"
                          @click="zzzMid"
                          >学习课程
                          <v-icon right> mdi-play</v-icon>
                        </v-btn>
                      </v-card-actions>
                      <div class="left-icon">
                        <svg-icon
                          :url="
                            require('@/assets/svgimgs/undraw_reading_book_re_kqpk.svg')
                          "
                          width="5.5rem"
                          height="6.1rem"
                        ></svg-icon>
                      </div>
                    </v-card>
                  </v-col>

                  <!-- 第三卡片除平板外 -->
                  <v-col
                    cols="12"
                    md="12"
                    lg="4"
                    v-if="
                      !$vuetify.breakpoint.md &&
                      (userPreferrence.cards.motto ||
                        (!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm))
                    "
                  >
                    <v-lazy
                      v-model="lazy.motto"
                      :options="{
                        threshold: 0.5,
                      }"
                      min-height="50"
                      transition="fade-transition"
                    >
                      <!-- 每日一句 -->
                      <daily-sentence class="detail-card rounded-lg">
                      </daily-sentence>
                    </v-lazy>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    lg="8"
                    v-if="
                      !$vuetify.breakpoint.md &&
                      (userPreferrence.cards.statistics ||
                        (!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm))
                    "
                  >
                    <v-lazy
                      v-model="lazy.statistics"
                      :options="{
                        threshold: 0.5,
                      }"
                      min-height="50"
                      transition="fade-transition"
                    >
                      <!-- 一周统计 -->
                      <home-stastic-card
                        class="detail-card rounded-lg"
                      ></home-stastic-card>
                    </v-lazy> </v-col
                  ><!-- END 第三卡片除平板外 -->
                </v-row>
              </v-col>
            </v-row>

            <v-row v-if="$vuetify.breakpoint.md">
              <!-- 第三卡片平板 -->
              <v-col cols="12" md="6" lg="4">
                <v-lazy
                  v-model="lazy.motto"
                  :options="{
                    threshold: 0.5,
                  }"
                  min-height="50"
                  transition="fade-transition"
                >
                  <!-- 每日一句 -->
                  <daily-sentence class="detail-card rounded-lg">
                  </daily-sentence>
                </v-lazy>
              </v-col>
              <v-col cols="12" md="6" lg="8">
                <v-lazy
                  v-model="lazy.statistics"
                  :options="{
                    threshold: 0.5,
                  }"
                  min-height="50"
                  transition="fade-transition"
                >
                  <!-- 一周统计 -->
                  <home-stastic-card
                    class="detail-card rounded-lg"
                  ></home-stastic-card>
                </v-lazy> </v-col
              ><!-- END 第三卡片平板 -->
            </v-row>

            <v-row>
              <v-col
                class="water-card"
                cols="6"
                md="4"
                lg="3"
                v-if="userPreferrence.cards.wordtable"
              >
                <v-lazy
                  v-model="lazy.wordtable"
                  :options="{
                    threshold: 0.5,
                  }"
                  min-height="50"
                  transition="fade-transition"
                >
                  <!-- 单词本 -->
                  <word-book
                    style="height: 13rem"
                    class="detail-card rounded-lg"
                  ></word-book>
                </v-lazy>
              </v-col>
              <v-col
                class="water-card"
                cols="6"
                md="4"
                lg="3"
                v-if="userPreferrence.cards.classTable"
              >
                <v-lazy
                  v-model="lazy.classTable"
                  :options="{
                    threshold: 0.5,
                  }"
                  min-height="50"
                  transition="fade-transition"
                >
                  <!-- 课程订阅 -->
                  <class-sub-scribe
                    style="height: 13rem"
                    class="detail-card rounded-lg"
                  >
                  </class-sub-scribe>
                </v-lazy>
              </v-col>
              <v-col
                cols="12"
                md="4"
                lg="3"
                v-if="userPreferrence.cards.emptyNoice"
              >
                <v-lazy
                  v-model="lazy.emptyNoice"
                  :options="{
                    threshold: 0.5,
                  }"
                  min-height="50"
                  transition="fade-transition"
                >
                  <!-- 白噪音 -->
                  <empty-noice
                    :style="$vuetify.breakpoint.xs ? '' : 'height: 13rem'"
                    class="detail-card rounded-lg"
                  ></empty-noice>
                </v-lazy>
              </v-col>
              <v-col
                class="water-card"
                cols="6"
                md="4"
                lg="3"
                v-if="userPreferrence.cards.focusMode"
              >
                <v-lazy
                  v-model="lazy.focusMode"
                  :options="{
                    threshold: 0.5,
                  }"
                  min-height="50"
                  transition="fade-transition"
                >
                  <!-- 番茄钟 -->
                  <focus-mode
                    style="height: 13rem"
                    class="detail-card rounded-lg"
                  >
                  </focus-mode>
                </v-lazy>
              </v-col>
              <v-col
                class="water-card"
                cols="6"
                md="4"
                lg="3"
                v-if="userPreferrence.cards.mileStone"
              >
                <v-lazy
                  v-model="lazy.mileStone"
                  :options="{
                    threshold: 0.5,
                  }"
                  min-height="50"
                  transition="fade-transition"
                >
                  <!-- 倒计时日历 -->
                  <mile-stone
                    style="height: 13rem"
                    class="detail-card rounded-lg"
                  >
                  </mile-stone>
                </v-lazy>
              </v-col>
              <v-col cols="6" md="4" lg="2" class="setting-card">
                <v-lazy
                  v-model="lazy.setting"
                  :options="{
                    threshold: 0.5,
                  }"
                  min-height="50"
                  transition="fade-transition"
                >
                  <!-- 管理卡片开启关闭 -->
                  <setting-home
                    style="height: 13rem"
                    class="detail-card rounded-lg"
                  ></setting-home>
                </v-lazy>
              </v-col>
            </v-row>
          </v-container>
          <div class="bottom">
            <van-divider class="divider">NOTEVER</van-divider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Divider } from "vant";
import { mapGetters } from "vuex";
import HomeAppHead from "@/views/home/components/homeAppHead";
import {
  avatrTool,
  buttonVibrate,
  setSystemColor,
  userInfo,
} from "@/mixin/mixin";
import SearchBox from "@/views/home/components/searchBox";
import { isEmpty } from "@/utils/common";
import PieChart from "@/views/home/components/pieChart";
import SvgIcon from "@/components/beauty/svgIcon";
import $api from "@/api/api";

export default {
  name: "homeIndex",
  mixins: [setSystemColor, avatrTool, buttonVibrate, userInfo],
  components: {
    GoleDialog: () => import("@/views/home/components/goleDialog"),
    SettingHome: () => import("@/views/home/components/settingHome"),
    mileStone: () => import("@/views/home/components/mileStone"),
    FocusMode: () => import("@/views/home/components/focusMode"),
    EmptyNoice: () => import("@/views/home/components/emptyNoice"),
    ClassSubScribe: () => import("@/views/home/components/classSubScribe"),
    WordBook: () => import("@/views/home/components/wordBook"),
    HomeStasticCard: () => import("@/views/home/components/homeStasticCard"),
    DailySentence: () => import("@/components/cards/dailySentence"),
    SvgWave: () => import("@/components/beauty/svgWave"),
    SvgIcon,
    PieChart,
    SearchBox,
    HomeAppHead,
    [Divider.name]: Divider,
  },
  computed: {
    ...mapGetters({
      auth: "auth",
      isApp: "isApp",
      appTheme: "appTheme",
      appThemeConfig: "appThemeConfig",
      systemBarHeight: "systemBarHeight",
      wordSearchHistory: "wordSearchHistory",
      userPreferrence: "userPreferrence",
    }),
    wordProgress() {
      // 同步
      let words;
      if (
        this.userPreferrence.gole.reviewWordGole +
          this.userPreferrence.gole.newWordGole <=
        0
      ) {
        words = 100;
      } else {
        words =
          ((this.learnToday.wordsNew + this.learnToday.wordsReview) * 100) /
          (this.userPreferrence.gole.reviewWordGole +
            this.userPreferrence.gole.newWordGole);
      }
      return words;
    },
    classProgress() {
      let classes;
      if (this.userPreferrence.gole.classGole <= 0) {
        classes = 100;
      } else {
        classes =
          (this.learnToday.classCount * 100) /
          this.userPreferrence.gole.classGole;
      }
      return classes;
    },
  },
  data: () => ({
    lazy: {
      setting: false,
      motto: false,
      statistics: false,
      wordtable: false,
      classTable: false,
      emptyNoice: false,
      focusMode: false,
      mileStone: false,
    },
    grapheDetails: {
      a: {
        id: "a",
        value: 0,
      },
      b: {
        id: "b",
        value: 0,
      },
    },
    learnToday: {
      classCount: 0,
      wordsBingo: false,
      classBingo: false,
      wordsReview: 0,
      wordsNew: 0,
      classTime: 0,
      wordsTime: 0,
    },
    total: {
      knownWords: 0,
      totalWord: 0,
      learnedPassage: 0,
    },
    onSearch: false,
    showGoleDialog: false,
    firstCreat: true,
  }),
  methods: {
    isEmpty,
    refreshCircle() {
      let wordProgress = this.wordProgress;
      if (this.learnToday.wordsBingo) {
        wordProgress = 100;
      }
      let classProgress = this.classProgress;
      if (this.learnToday.classBingo) {
        classProgress = 100;
      }
      this.grapheDetails.a.value = Math.ceil(
        wordProgress / 2 + classProgress / 2
      );

      if (this.userPreferrence.gole.timeGole <= 0) {
        this.grapheDetails.b.value = 100;
      } else {
        this.grapheDetails.b.value = Math.ceil(
          ((this.learnToday.classTime + this.learnToday.wordsTime) * 100) /
            this.userPreferrence.gole.timeGole
        );
      }
    },
    goLearnWord() {
      this.$router.push("learnword");
    },
    goLearnClass() {
      this.$router.push("learnclass");
    },
    setGole() {
      this.$refs.goleDialog.showDialog();
    },
    getHomeData() {
      $api
        .getHomeData()
        .then((ret) => {
          let res = ret.data;
          if (res.code === 0) {
            this.$store.dispatch("setUserPreferrenceHomeInfo", res.data);
            this.learnToday = res.data.records;
            this.total = res.data.total;
            // TODO
            this.total.learnedPassage = 12;
            this.refreshCircle();
          } else {
            this.$toast.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    doEncourage(v) {
      if (v === "time") {
        if (this.learnToday.classTime + this.learnToday.wordsTime === 0) {
          this.$toast("今天您还没有学习！");
        } else {
          let time = this.learnToday.classTime + this.learnToday.wordsTime;
          this.$toast.info("您今天已经学习 " + time + " 分钟了！");
        }
      }
      if (v === "word") {
        if (this.learnToday.wordsNew + this.learnToday.wordsReview === 0) {
          this.$toast("今天您还没有背单词！");
        } else {
          this.$toast.info(
            "您今天已经学习 " +
              this.learnToday.wordsNew +
              this.learnToday.wordsReview +
              " 个单词了！"
          );
        }
      }
      if (v === "class") {
        if (this.learnToday.classCount === 0) {
          this.$toast("今天您还没有浏览课程！");
        } else {
          this.$toast.info(
            "您今天已经学习 " + this.learnToday.classCount + " 篇课程了！"
          );
        }
      }
    },
    removeWordHistory(word) {
      let arr = this.wordSearchHistory;
      arr = arr.filter((item) => {
        return item !== word;
      });
      this.$store.dispatch("setWordSearchHistory", arr);
    },
    quickSearch(word) {
      this.$refs.searchBox.searchWord = word;
      this.$refs.searchBox.doFocus();
    },
    showSearch() {
      console.info("显示搜索");
      this.$refs.searchBox.searchWord = "";
      this.$refs.searchBox.searchResults = [];
      this.onSearch = true;
      this.$refs.searchBox.doFocus();
      this.$store.dispatch("setShowSysAppTab", false);
    },
    hideSearch() {
      console.info("隐藏搜索");
      this.$store.dispatch("setShowSysAppTab", true);
      this.onSearch = false;
    },
    openDrawer() {
      this.$store.dispatch("setShowDrawer", true);
    },
    init() {
      this.$store.dispatch("setShowSysAppTab", true);
      this.$store.dispatch("setAppSystemBarColor", "#ffd7af");
      this.setSystemFootColor(
        this.appThemeConfig.frameWorkColorList[0].footerBarColer
      );
      this.getHomeData();
    },
  },
  created() {
    this.init();
    console.info("home组件被创建");
  },
  mounted() {
    console.info("home组件被挂载");
  },
  activated() {
    console.info("home组件被激活");
    if (this.firstCreat) {
      this.firstCreat = false;
    } else {
      this.onSearch = false;
      this.$refs.appHead.offsetTop = 0;
      document.getElementById("main").scrollTo(0, 0);
      this.init();
    }
  },
  deactivated() {
    console.info("home组件被暂停");
  },
};
</script>

<style lang="scss" scoped>
.search {
  position: fixed;
  z-index: 6;
  width: 100%;
  margin-top: 8px;
}

.search-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 5;
  opacity: 0.8;

  .mask-content {
    height: 100%;
  }

  .history {
    margin: calc(56px + 0.7rem) auto 0 auto;
    padding-left: 0.1rem;
    width: calc(100% - 1.2rem);
    height: 2rem;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: scroll;
    justify-content: flex-start;
    border-radius: 3rem;

    &::-webkit-scrollbar {
      height: 0 !important;
    }

    .word {
      margin: 0.25rem 0.3rem 0.25rem 0;
      flex-shrink: 0;
    }
  }

  .nodata {
    width: 100%;
    height: calc(100% - 56px - 2.7rem);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    color: #888888;
    letter-spacing: 0.2rem;

    > div {
      margin-top: -6rem;
    }
  }
}

.home-content {
  width: 100%;
  height: auto;
  min-height: 100%;
  position: relative;
  word-break: break-word;

  .svg-wave {
    top: 12rem;
    left: 0;
    position: absolute;
    width: 100%;
    height: 15rem;
    z-index: 0;
    opacity: 0.3;
    overflow: hidden;
  }

  .home-back-ground-a {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 30rem;
    z-index: 0;
    background-color: #ffd7af;
  }

  .home-back-ground-b {
    top: 27rem;
    left: 0;
    position: absolute;
    width: 100%;
    height: 300px;
    z-index: 0;
    background-image: linear-gradient(#ffaf64, #ffffff);
  }

  .content {
    top: 6px;
    left: 0;
    position: absolute;
    width: 100%;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    .container {
      width: 100%;
      padding: 12px;
      margin-right: auto;
      margin-left: auto;
    }

    @media (min-width: 960px) {
      .container {
        max-width: 900px;
      }
    }
    @media (min-width: 1264px) {
      .container {
        max-width: 1085px;
      }
    }
    @media (min-width: 1904px) {
      .container {
        max-width: 1185px;
      }
    }

    .home-cards {
      padding: 0 0.45rem;
      margin: 0 auto 0 auto;

      //::v-deep .col-* {
      //}
      .water-card {
        flex: 1 0 50%;
        max-width: 100%;
      }

      .detail-card {
        position: relative;
        z-index: 1;
        margin: 0.3rem 0 -0.6rem 0;
        width: 100%;
        height: 11rem;
        box-shadow: 0.1rem 0.3rem 0.8rem rgba(98, 84, 73, 0.3) !important;
        //background-image: linear-gradient(
        //  to bottom right,
        //  #fffaf6,
        //  #fff3e6,
        //  #ffeedd
        //);
        background-color: #fffcfa;

        .left-icon {
          position: absolute;
          left: -0.5rem;
          bottom: -0.5rem;
          /*background-color: #e7e7e7;*/
          border-radius: 50%;
          padding: 1.5rem;
        }

        .right-button {
          position: absolute;
          right: 0.6rem;
          top: 0.8rem;
          height: 1.8rem;
          padding: 0.5rem;
          color: #dc7841;
          font-size: 0.9rem;
          font-weight: 700;
          width: 6.5rem;

          ::v-deep .v-btn__content {
            opacity: 1 !important;
          }
        }

        .card-title {
          font-weight: 700;
          font-size: 1.1rem;
          letter-spacing: 0.15rem;
          color: #404040;
          margin-top: -0.5rem;
        }

        .detail-card-content {
          position: absolute;
          right: 1rem;
          bottom: 1.5rem;
          width: 21.5rem;
          height: 3.5rem;
          color: #505050;
          margin-top: -1.5rem;
          padding: 0 0.6rem 0 7.6rem;
          display: flex;
          justify-content: space-between;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.375rem;
          letter-spacing: 0.0071428571em;

          .detail-card-progress {
            margin: 0.1rem 0 0 0;
          }

          .detail-card-details {
            width: 11rem;
            letter-spacing: 0.05rem;

            .have-learned {
              height: 1.8rem;
            }

            .have-learned-val {
              color: #f1915b;
              font-family: dosisbold, sans-serif;
              font-size: 1.2rem;
            }

            .have-learned-r {
              font-size: 0.6rem;
            }

            .today-left {
              height: 2.2rem;
            }

            .today-center-l {
              color: #f1915b;
              font-family: dosisbold, sans-serif;
              font-size: 1.4rem;
            }

            .today-center-r {
              font-family: dosisbold, sans-serif;
              font-size: 1.3rem;
              color: #909090;
            }

            .today-right {
              font-size: 0.6rem;
            }
          }
        }
      }
    }

    .set-gole {
      width: 100%;
      height: 3rem;
      margin: 0.7rem auto 1.2rem 0;
      display: flex;
      justify-content: center;

      .set-gole-btn {
        width: 12rem;
        letter-spacing: 0.1rem;
        //opacity: 0.95;
        color: #e5814a;
      }
    }

    .text-details {
      margin: -1.9rem auto 0rem auto;
      padding: 0 1rem;
      width: 15rem;
      height: 5rem;
      //background-color: #8ca0ff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;

      .text-details-group {
        flex-basis: 10rem;

        .text-details-value {
          font-family: dosisbold, sans-serif;
          font-size: 1.1rem;
          font-weight: 700;
          color: #e5814a;
          letter-spacing: 0.2rem;
          margin: 0 0 0.4rem 0;
          max-width: 4.2rem;
          height: 2rem;
          white-space: nowrap;
          overflow-x: scroll;

          ::v-deep .v-btn__content {
            opacity: 1;
          }
        }

        .text-details-desc {
          font-size: 0.8rem;
          font-weight: 500;
          letter-spacing: 0.05rem;
          line-height: 0.8rem;
          margin-top: -0.2rem;

          > i {
            margin: 0 0.2rem;
            font-size: 0.8rem;
            line-height: 0.9rem;
          }
        }
      }
    }

    .main-chart {
      width: calc(100% - 8rem) !important;
      height: 17rem !important;
      margin: 0 auto;
    }

    .welcome {
      width: 100%;
      height: 4rem;
      margin-top: 0rem;
      //background-color: #7a7a7a;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .welcome-search {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .welcome-text {
        width: 100%;
        height: 3rem;
        //background-color: #8ca0ff;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0rem 0.5rem 0 0.2rem;
        letter-spacing: 0.2rem;

        .word {
          font-weight: 300;
          font-size: 0.8rem;
          height: 1.1rem;
          line-height: 1.1rem;
        }

        .name {
          letter-spacing: 0.03rem;
          font-weight: 700;
          font-size: 1.1rem;
          height: 1.9rem;
          line-height: 1.9rem;
          //background-color: #505050;
        }
      }

      .my-avatar {
        margin: 0.4rem;
        background-color: #eeeeee;
        border: 0.5px solid #e7e7e7;
      }
    }
  }
}

.bottom {
  margin: 3rem 0 calc(56px + 2rem) 0;
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: pangmenzhengdaoregular, NotoSansHans， 微软雅黑, sans-serif;
  font-size: 0.9rem;
  letter-spacing: 0.2rem;

  .divider {
    width: 60%;
    height: 3rem;
    color: #e5e5e5;
    border-color: #d7d7d7;
  }
}

.colorful {
  .home-content {
    .home-back-ground-a {
      background-color: #ffd7af;
    }

    .home-back-ground-b {
      background-image: linear-gradient(#ffd7af, #ffffff);
    }

    .content {
    }
  }
}

.purple {
  .home-content {
    .home-back-ground-a {
      background-color: #8ca0ff;
    }

    .home-back-ground-b {
      background-image: linear-gradient(#8ca0ff, #ffffff);
    }

    .content {
    }
  }
}

#home {
  height: auto;
  min-height: 100%;
}
</style>
