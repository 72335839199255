import Vue from "vue";
import Vuex from "vuex";

let newDate = new Date();
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    auth: localStorage.getItem("auth")
      ? JSON.parse(localStorage.getItem("auth"))
      : {
          expire: null,
          skey: null,
          token: null,
          userdetail: null,
        },
    appTheme: localStorage.getItem("app_theme")
      ? localStorage.getItem("app_theme")
      : "colorful",
    appThemeConfig: {},
    appLoading: {
      show: false,
      opacity: 1,
      themes: "#e3e8ff",
      color: "#4c62cc",
      time: 1500,
    },
    browser: "others",
    screenWidth: null,
    screenHeight: null,
    appSystemBarColor: "#ffffff",
    appTabIndex: 0, //应用tab序号
    showDrawer: false,
    showSysAppTab: true,
    isApp: false, //是不是app端，默认false
    appStatus: 1, // 1前台，0后台
    systemBarHeight: 25, //系统顶栏高度
    wordSearchHistory: localStorage.getItem("word_search_history")
      ? JSON.parse(localStorage.getItem("word_search_history"))
      : [],
    focusMode: {
      startTime: null,
      endTime: null,
      pauseTime: null,
    },
    touched: false,
    wordTable: {},
    userPreferrence: localStorage.getItem("preferrence")
      ? JSON.parse(localStorage.getItem("preferrence"))
      : {
          gole: {
            timeGole: 60,
            newWordGole: 1,
            reviewWordGole: 5,
            classGole: 1,
          },
          cards: {
            motto: true,
            statistics: true,
            wordtable: true,
            classTable: true,
            emptyNoice: true,
            focusMode: true,
            mileStone: true,
          },
          mileStone: {
            name: String(newDate.getFullYear() + 1).substring(2, 4) + "考研",
            endTime: String(newDate.getFullYear()) + "-12-25",
          },
        },
    learnTemp: localStorage.getItem("learnTemp")
      ? JSON.parse(localStorage.getItem("learnTemp"))
      : {
          flashIndex: {
            date: "",
            index: 0,
          }, //闪过进度
        },
  },
  getters: {
    auth: (state) => state.auth,
    appTheme: (state) => state.appTheme,
    appThemeConfig: (state) => state.appThemeConfig,
    appLoading: (state) => state.appLoading,
    browser: (state) => state.browser,
    screenWidth: (state) => state.screenWidth,
    screenHeight: (state) => state.screenHeight,
    appSystemBarColor: (state) => state.appSystemBarColor,
    appBgColor: (state) => state.appBgColor,
    appTabIndex: (state) => state.appTabIndex,
    showDrawer: (state) => state.showDrawer,
    showSysAppTab: (state) => state.showSysAppTab,
    isApp: (state) => state.isApp,
    appStatus: (state) => state.appStatus,
    systemBarHeight: (state) => state.systemBarHeight,
    wordSearchHistory: (state) => state.wordSearchHistory,
    focusMode: (state) => state.focusMode,
    touched: (state) => state.touched,
    wordTable: (state) => state.wordTable,
    userPreferrence: (state) => state.userPreferrence,
    learnTemp: (state) => state.learnTemp,
  },
  mutations: {
    doLongLogin(state, auth) {
      //第一个参数，操作谁 操作state
      //这句话修改 vuex 中 Authorization 的值
      state.auth = auth;
      //这句话将数据存入 localstorage 中
      localStorage.setItem("auth", JSON.stringify(auth));
      console.log(">>>Store>>> 保存auth到store和本地存储成功");
    },
    doShortLogin(state, auth) {
      //第一个参数，操作谁 操作state
      //这句话修改 vuex 中 Authorization 的值
      state.auth = auth;
      //这句话将空数据存入 localstorage 中
      localStorage.removeItem("auth");
      console.log(">>>Store>>> 保存auth到store成功");
    },
    clearLogin(state) {
      //第一个参数，操作谁 操作state
      //这句话修改 vuex 中 Authorization 的值
      state.auth = {
        expire: null,
        skey: null,
        token: null,
        userdetail: null,
      };
      //这句话将空数据存入 localstorage 中
      localStorage.removeItem("auth");
      console.log(">>>Store>>> 移除auth成功");
    },
    setAppTheme(state, theme) {
      state.appTheme = theme;
      localStorage.setItem("app_theme", theme);
      console.log(">>>Store>>> 保存主题配置成功");
    },
    setAppThemeConfig(state, config) {
      state.appThemeConfig = config;
    },
    setAppLoading(state, value) {
      state.appLoading = value;
    },
    setBrowser(state, value) {
      state.browser = value;
    },
    setScreenWidth(state, value) {
      state.screenWidth = value;
    },
    setScreenHeight(state, value) {
      state.screenHeight = value;
    },
    setAppSystemBarColor(state, value) {
      state.appSystemBarColor = value;
    },
    setAppTabIndex(state, index) {
      state.appTabIndex = index;
    },
    setShowDrawer(state, value) {
      state.showDrawer = value;
    },
    setShowSysAppTab(state, value) {
      state.showSysAppTab = value;
    },
    setIsApp(state, value) {
      state.isApp = value;
    },
    setAppStatus(state, value) {
      state.appStatus = value;
    },
    setSystemBarHeight(state, value) {
      state.systemBarHeight = value;
    },
    setWordSearchHistory(state, value) {
      state.wordSearchHistory = value;
      localStorage.setItem("word_search_history", JSON.stringify(value));
      console.log(">>>Store>>> 保存单词搜索记录成功");
    },
    setFocusMode(state, value) {
      state.focusMode = value;
    },
    pauseFocusMode(state) {
      state.focusMode.pauseTime = parseInt(new Date().getTime());
    },
    resumeFocusMode(state) {
      state.focusMode.pauseTime = null;
    },
    setTouched(state) {
      state.touched = true;
    },
    setUserPreferrence(state, preferrence) {
      state.userPreferrence = preferrence;
      //这句话将数据存入 localstorage 中
      localStorage.setItem("preferrence", JSON.stringify(preferrence));
      console.log(">>>Store>>> 保存preferrence到store和本地存储成功");
    },
    setUserPreferrencemileStone(state, mileStone) {
      state.userPreferrence.mileStone = mileStone;
      //这句话将数据存入 localstorage 中
      localStorage.setItem(
        "preferrence",
        JSON.stringify(state.userPreferrence)
      );
      console.log(">>>Store>>> 保存preferrence到store和本地存储成功");
    },
    setUserPreferrenceHomeInfo(state, res) {
      state.userPreferrence.cards = res.cards;
      state.userPreferrence.gole = res.gole;
      //这句话将数据存入 localstorage 中
      localStorage.setItem(
        "preferrence",
        JSON.stringify(state.userPreferrence)
      );
      console.log(">>>Store>>> 保存preferrence到store和本地存储成功");
    },
    setUserPreferrenceHomeCards(state, res) {
      state.userPreferrence.cards = res;
      //这句话将数据存入 localstorage 中
      localStorage.setItem(
        "preferrence",
        JSON.stringify(state.userPreferrence)
      );
      console.log(">>>Store>>> 保存preferrence到store和本地存储成功");
    },
    setWordTable(state, value) {
      state.wordTable = value;
    },
    setUserPreferrenceHomeGole(state, res) {
      state.userPreferrence.gole = res;
      //这句话将数据存入 localstorage 中
      localStorage.setItem(
        "preferrence",
        JSON.stringify(state.userPreferrence)
      );
      console.log(">>>Store>>> 保存preferrence到store和本地存储成功");
    },
    setLearnTemp(state, value) {
      state.learnTemp = value;
      //这句话将数据存入 localstorage 中
      localStorage.setItem("learnTemp", JSON.stringify(state.learnTemp));
      console.log(">>>Store>>> 保存learnTemp到store和本地存储成功");
    },
    setLearnTempFlashIndex(state, value) {
      state.learnTemp.flashIndex = value;
      localStorage.setItem("learnTemp", JSON.stringify(state.learnTemp));
      console.log(">>>Store>>> 保存learnTemp到store和本地存储成功");
    },
  },
  actions: {
    setAppTheme({ commit }, theme) {
      commit("setAppTheme", theme);
    },
    setAppThemeConfig({ commit }, config) {
      commit("setAppThemeConfig", config);
    },
    setAppLoading({ commit }, value) {
      commit("setAppLoading", value);
    },
    setBrowser({ commit }, value) {
      commit("setBrowser", value);
    },
    setScreenWidth({ commit }, value) {
      commit("setScreenWidth", value);
    },
    setScreenHeight({ commit }, value) {
      commit("setScreenHeight", value);
    },
    setAppSystemBarColor({ commit }, appSystemBarColor) {
      commit("setAppSystemBarColor", appSystemBarColor);
    },
    setAppTabIndex({ commit }, appTabIndex) {
      commit("setAppTabIndex", appTabIndex);
    },
    setShowDrawer({ commit }, showDrawer) {
      commit("setShowDrawer", showDrawer);
    },
    setShowSysAppTab({ commit }, showSysAppTab) {
      commit("setShowSysAppTab", showSysAppTab);
    },
    setIsApp({ commit }, isApp) {
      commit("setIsApp", isApp);
    },
    setAppStatus({ commit }, appStatus) {
      commit("setAppStatus", appStatus);
    },
    setSystemBarHeight({ commit }, systemBarHeight) {
      commit("setSystemBarHeight", systemBarHeight);
    },
    setWordSearchHistory({ commit }, wordSearchHistory) {
      commit("setWordSearchHistory", wordSearchHistory);
    },
    setFocusMode({ commit }, focusMode) {
      commit("setFocusMode", focusMode);
    },
    setTouched({ commit }) {
      commit("setTouched");
    },
    setUserPreferrence({ commit }, userPreferrence) {
      commit("setUserPreferrence", userPreferrence);
    },
    setUserPreferrencesmileStone({ commit }, mileStone) {
      commit("setUserPreferrencemileStone", mileStone);
    },
    setWordTable({ commit }, wordTable) {
      commit("setWordTable", wordTable);
    },
    setUserPreferrenceHomeInfo({ commit }, res) {
      commit("setUserPreferrenceHomeInfo", res);
    },
    setLearnTemp({ commit }, learnTemp) {
      commit("setLearnTemp", learnTemp);
    },
    setLearnTempFlashIndex({ commit }, res) {
      commit("setLearnTempFlashIndex", res);
    },
  },
  modules: {},
});
