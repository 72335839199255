import { isEmpty } from "@/utils/common";
import _this from "@/main";

export default {
  getPushCid() {
    let plus = window.plus;
    // 页面加载时触发
    let pinf = plus.push.getClientInfo();
    //客户端标识
    return pinf.clientid;
  },
  initListen() {
    let plus = window.plus;
    //监听系统通知栏消息点击事件
    plus.push.addEventListener(
      "click",
      (msg) => {
        console.log("unipush点击普通消息：", msg);
        //处理点击消息的业务逻辑代码
        _handlePushClick(msg);
      },
      false
    );
    //监听接收透传消息事件
    plus.push.addEventListener(
      "receive",
      (msg) => {
        console.log("unipush监听到消息：", msg);
        //处理透传消息的业务逻辑代码
        _handlePushReceive(msg);
      },
      false
    );

    const _handlePushClick = (message) => {
      /**
       * 实际开发中，这里可能是跳转到某个页面等操作，请根据自身业务需求编写。
       */
      try {
        if (message.payload === "focusModeAlert") {
          if (
            isEmpty(_this.$route.path) ||
            !_this.$route.path.match(/^\/focus/i)
          ) {
            _this.$router.push("focus");
          }
        }
      } catch (e) {
        console.log("发生异常:" + e);
      }
    };
    // eslint-disable-next-line no-unused-vars
    const _handlePushReceive = (message) => {
      /**
       * 实际开发中，这里可能是跳转到某个页面等操作，请根据自身业务需求编写。
       */
    };
  },
};
