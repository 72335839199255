const purpleTheme = {
  dark: true,
  appBgColor: "#383b49",
  frameWorkColorList: [
    {
      color: "#4659be", //deep-purple lighten-2
      systemBarColor: "#4659be",
      headColor: "#8ca0ff",
      footColor: "#758BFF",
      footerBarColer: "#4659be",
      gradientColor: "to top right, rgba(117,139,255,0.6), rgba(25,32,72,.7)",
    },
    {
      color: "#3f3eb0",
      systemBarColor: "#3f3eb0",
      headColor: "#8181f5",
      footColor: "#6F6EF4",
      footerBarColer: "#3f3eb0",
      gradientColor: "to top right, rgba(111,110,244,0.6), rgba(25,32,72,.7)",
    },
    {
      color: "#513bbb",
      systemBarColor: "#513bbb",
      headColor: "#9782fd",
      footColor: "#846BFF",
      footerBarColer: "#513bbb",
      gradientColor: "to top right, rgba(132,107,255,0.6), rgba(25,32,72,.7)",
    },
    {
      color: "#4f22a6",
      systemBarColor: "#4f22a6",
      headColor: "#9563f5",
      footColor: "#844bf3",
      footerBarColer: "#4f22a6",
      gradientColor: "to top right, rgba(132,75,243,0.6), rgba(25,32,72,.7)",
    },
  ],
};

export { purpleTheme };
