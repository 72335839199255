const colorfulTheme = {
  dark: false,
  appBgColor: "#ffffff", //全局背景色
  color: "#f7e493", //主配色
  systemBarColor: "#eeeeee", //状态栏颜色
  headColor: "#ffffff", //导航栏颜色
  footColor: "#ffffff", //底部导航颜色
  footerBarColer: "#ffffff", //系统底部颜色
  frameWorkColorList: [
    {
      color: "#ffd7af", //deep-colorful lighten-2
      systemBarColor: "#ffd3ad",
      headColor: "#ffd7af",
      footColor: "#ffffff",
      footerBarColer: "#ffffff",
      gradientColor: "to top right, rgba(117,139,255,0.6), rgba(25,32,72,.7)",
    },
    {
      color: "#93f3eb",
      systemBarColor: "#6cdad1",
      headColor: "#93f3eb",
      footColor: "#ffffff",
      footerBarColer: "#ffffff",
      gradientColor:
        "to bottom right, rgba(147,243,235,1), rgba(213,255,247,.5)",
    },
    {
      color: "#92f3c2",
      systemBarColor: "#6dd9a2",
      headColor: "#92f3c2",
      footColor: "#ffffff",
      footerBarColer: "#ffffff",
      gradientColor: "to top right, rgba(132,107,255,0.6), rgba(25,32,72,.7)",
    },
    {
      color: "#ecefed",
      systemBarColor: "#bbbdbb",
      headColor: "#ecefed",
      footColor: "#ffffff",
      footerBarColer: "#ffffff",
      gradientColor: "to top right, rgba(132,75,243,0.6), rgba(25,32,72,.7)",
    },
  ],
};

export { colorfulTheme };
