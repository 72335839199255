<template>
  <v-system-bar
    fixed
    :dark="appThemeConfig.dark"
    class="system-bar"
    :elevation="0"
    :color="appSystemBarColor"
    :height="systemBarHeight + 2"
  >
  </v-system-bar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "appSystemBar",
  props: {
    frameWorkColor: {
      type: Object,
      default() {
        return {
          color: "#758BFF",
        };
      },
    },
  },
  computed: {
    ...mapGetters({
      systemBarHeight: "systemBarHeight",
      appThemeConfig: "appThemeConfig",
      appSystemBarColor: "appSystemBarColor",
    }),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.system-bar {
  z-index: 3;
}
</style>
