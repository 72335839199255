// 深度拷贝对象
export function deepClone(source) {
  if (!source && typeof source !== "object") {
    throw new Error("error arguments", "shallowClone");
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

// 获得字符串字符长度，中文2，英文1
export function getStrLength(str) {
  let realLength = 0;
  let len = str.length;
  let charCode = -1;
  for (let i = 0; i < len; i++) {
    charCode = str.charCodeAt(i);
    if (charCode >= 0 && charCode <= 128) realLength += 1;
    else realLength += 2;
  }
  return realLength;
}

// 去除字符串左右两边空格
export function trimLR(str) {
  return str.replace(/(^\s*)|(\s*$)/g, "");
}

//判断是否为空
export function isEmpty(o) {
  return (
    o === undefined ||
    o === "undefined" ||
    o == null ||
    o === "" ||
    o === "null" ||
    o === 0 ||
    isObjEqual(o, []) ||
    isObjEqual(o, {})
  );
}

//比较2个对象是否相同
export function isObjEqual(o1, o2) {
  return Object.entries(o1).toString() === Object.entries(o2).toString();
}

//距离今日日期差
export function computeToday(sdate) {
  let d, d2, iDays;
  d = new Date();
  d2 = new Date(sdate);
  iDays = parseInt((d2 - d) / 1000 / 60 / 60 / 24);
  return iDays;
}

export function getTimeStr(timeNum) {
  let y, m, d, h, mm, s;
  let date = new Date();
  if (!isEmpty(timeNum)) {
    date = new Date(timeNum);
  }
  y = date.getFullYear();
  m = date.getMonth() + 1;
  d = date.getDate();
  h = date.getHours();
  mm = date.getMinutes();
  s = date.getSeconds();
  m = m < 10 ? "0" + m : m;
  d = d < 10 ? "0" + d : d;
  h = h < 10 ? "0" + h : h;
  mm = mm < 10 ? "0" + mm : mm;
  s = s < 10 ? "0" + s : s;
  const timeStr = y + "-" + m + "-" + d + " " + h + ":" + mm + ":" + s;
  return timeStr;
}

export function getDayStr(timeNum) {
  let y, m, d;
  let date = new Date();
  if (!isEmpty(timeNum)) {
    date = new Date(timeNum);
  }
  y = date.getFullYear();
  m = date.getMonth() + 1;
  d = date.getDate();
  m = m < 10 ? "0" + m : m;
  d = d < 10 ? "0" + d : d;
  return y + "-" + m + "-" + d;
}

export function forceRun() {
  const plus = window.plus;
  const main = plus.android.runtimeMainActivity();
  const activityManager = main.getSystemService(main.ACTIVITY_SERVICE);
  plus.android.importClass(activityManager);
  return activityManager.moveTaskToFront(main.getTaskId(), 0);
}

let g_wakelock = null;
//允许程序后台运行，以持续获取GPS位置
export function wakeLock() {
  try {
    const plus = window.plus;
    //Android
    const main = plus.android.runtimeMainActivity();
    const Context = plus.android.importClass("android.content.Context");
    const PowerManager = plus.android.importClass("android.os.PowerManager");
    const pm = main.getSystemService(Context.POWER_SERVICE);
    g_wakelock = pm.newWakeLock(PowerManager.PARTIAL_WAKE_LOCK, "ANY_NAME");
    g_wakelock.acquire();
    console.log("~~~进程保活启动");
  } catch (e) {
    console.log("发生异常:" + e);
  }
}

//结束程序后台运行
export function releaseWakeLock() {
  try {
    if (g_wakelock != null && g_wakelock.isHeld()) {
      g_wakelock.release();
      g_wakelock = null;
    }
    console.log("~~~进程保活释放");
  } catch (e) {
    console.log("发生异常:" + e);
  }
}

export function checkBrowser() {
  //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
  let ua = window.navigator.userAgent;
  // console.info("用户代理：", ua);
  ua = ua.toLowerCase();
  // alert(JSON.stringify(ua));
  //通过正则表达式匹配ua中是否含有MicroMessenger字符串
  let result = ua.match(/MicroMessenger/i);
  if (result && result.toString() === "micromessenger") {
    console.info("微信浏览器");
    return "wx";
  }
  if (ua.indexOf(" qq") > -1 && ua.indexOf("mqqbrowser") < 0) {
    //qq内置浏览器
    console.info("QQ内置浏览器");
    return "qq";
  }
  if (ua.indexOf("mqqbrowser") > -1 && ua.indexOf(" qq") < 0) {
    console.info("QQ浏览器");
    return "qqllq";
  }
  if (ua.indexOf("heytap") > -1) {
    console.info("一加套壳qq浏览器");
    return "heytap";
  }
  if (ua.indexOf("xiaomi") > -1) {
    console.info("小米套壳qq浏览器");
    return "xiaomi";
  }
  if (ua.indexOf("tbs") > -1) {
    console.info("其他X5内核浏览器");
    return "x5";
  }
  if (ua.indexOf("opera") > -1) {
    console.info("Opera浏览器");
    return "opera";
  } //判断是否Opera浏览器
  if (ua.indexOf("firefox") > -1) {
    console.info("Firefox浏览器");
    return "firefox";
  } //判断是否Firefox浏览器
  if (ua.indexOf("chrome") > -1) {
    let chromeVersion;
    let arr = ua.split(" ");
    for (let i = 0; i < arr.length; i++) {
      if (/chrome/i.test(arr[i])) chromeVersion = arr[i];
    }
    if (chromeVersion) {
      chromeVersion = Number(chromeVersion.split("/")[1].split(".")[0]);
    }
    console.info("Chrome浏览器" + chromeVersion);
    return "chrome—" + chromeVersion;
  }
  if (ua.indexOf("safari") > -1) {
    console.info("Safari浏览器");
    return "safari";
  } //判断是否Safari浏览器
  console.info("其他浏览器");
  return "other";
}
