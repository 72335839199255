<template>
  <v-expand-transition>
    <v-app-bar
      color="#ffd7af"
      :dark="appThemeConfig.dark"
      fixed
      inverted-scroll
      :elevation="this.offsetTop < 80 ? 0 : 3"
      v-show="this.offsetTop > 5"
      class="app-bar rounded-b-lg"
      :style="isApp ? `top: ${systemBarHeight}px` : ''"
      scroll-target="#main"
      v-scroll:#main="onScroll"
    >
      <v-app-bar-nav-icon @click="switchDrawer"></v-app-bar-nav-icon>

      <v-app-bar-title
        ><div class="title-group">
          <div class="main-title">今日任务</div>
          <div class="sub-title">完成40%</div>
        </div></v-app-bar-title
      >

      <v-spacer></v-spacer>

      <v-btn icon @click="showSearch">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-menu
        left
        bottom
        offset-y
        open-on-hover
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="zzzShort">
            <v-avatar :elevation="1" class="my-avatar" size="36">
              <img :src="userAvatar" alt="John" />
            </v-avatar>
          </v-btn>
        </template>

        <v-list>
          <v-list-item link>
            <v-list-item-title>个人信息</v-list-item-title> </v-list-item
          ><v-list-item link>
            <v-list-item-title>学习目标</v-list-item-title> </v-list-item
          ><v-list-item link>
            <v-list-item-title>退出登录</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!--    <template v-slot:extension> </template>-->
    </v-app-bar>
  </v-expand-transition>
</template>

<script>
import { mapGetters } from "vuex";
import { isEmpty } from "@/utils/common";
import { avatrTool, buttonVibrate } from "@/mixin/mixin";

export default {
  name: "homeAppHead",
  mixins: [avatrTool, buttonVibrate],
  computed: {
    ...mapGetters({
      isApp: "isApp",
      systemBarHeight: "systemBarHeight",
      appThemeConfig: "appThemeConfig",
    }),
  },
  data: () => ({
    offsetTop: 0,
    tab: null,
  }),
  created() {},
  methods: {
    isEmpty,
    onScroll(e) {
      if (!e || !e.target || !e.target.scrollTop) {
        return;
      }
      setTimeout(() => {
        this.offsetTop = e.target.scrollTop;
      }, 250);
    },
    switchDrawer() {
      this.zzzShort();
      this.$store.dispatch("setShowDrawer", !this.showDrawer);
    },
    showSearch() {
      this.zzzShort();
      this.$emit("showSearch");
    },
  },
};
</script>

<style lang="scss" scoped>
.app-bar {
  z-index: 2;
  .my-avatar {
    background-color: #eeeeee;
    border: 0.5px solid #e7e7e7;
  }
  .title-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .main-title {
      font-size: 15px;
    }
    .sub-title {
      font-size: 12px;
      color: #7a7a7a;
    }
  }
}
</style>
